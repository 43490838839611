// extracted by mini-css-extract-plugin
export var adress = "index-module--adress--HgEMi";
export var arrow = "index-module--arrow--e4Hxh";
export var button = "index-module--button--11jy-";
export var contact = "index-module--contact--dhaWr";
export var cta = "index-module--cta--a859b";
export var datenschutz = "index-module--datenschutz--ThyMl";
export var impressum = "index-module--impressum--96SgJ";
export var impressum_und_datenschutz = "index-module--impressum_und_datenschutz--gd37O";
export var left_container = "index-module--left_container--kW90Q";
export var left_content = "index-module--left_content--pvrne";
export var line = "index-module--line--nekk4";
export var logo = "index-module--logo--60Vd8";
export var mail = "index-module--mail--cKnif";
export var mainContainer = "index-module--mainContainer---xQaj";
export var middle_container = "index-module--middle_container--VVsNL";
export var phone = "index-module--phone--dHp27";
export var right_container = "index-module--right_container--bkJjE";
export var right_content = "index-module--right_content--RPeG4";
export var text_button_content = "index-module--text_button_content--vV9rN";