import * as React from 'react';
import { Link, PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// @ts-ignore
import greenLine from '../assets/images/green_line.png';
// @ts-ignore
import redLine from '../assets/images/red_line.png';
// @ts-ignore
import arrow from '../assets/images/arrow.png';
// @ts-ignore
import logo from '../assets/images/karl_wall_logo.png';

// @ts-ignore
import * as styles from './index.module.css';
import { LandingPageLayout } from '../layouts/LandingPage';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

interface DataProps {
  contactData: any;
  pageData: any;
  siteData: any;
}

const Homepage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const { contactData, pageData, siteData } = data;
  const { imageInstallateur, imageZimmer, metadata, opengraph } = pageData;
  const { title, canonicalSiteUrl } = siteData.siteMetadata;

  return (
    <LandingPageLayout>
      <section className={styles.mainContainer}>
        <div className={styles.left_container}>
          <Link to="/zimmer">
            <GatsbyImage image={getImage(imageInstallateur)} alt="Zimmer" />
            <div className={`${styles.text_button_content} ${styles.left_content}`}>
              <img className={styles.line} src={redLine} />
              <h2>
                <span>Karl Wall</span> <br />
                FRÜHSTÜCKSPENSION
              </h2>
              <div className={styles.button}>
                ENTER
                <img className={styles.arrow} src={arrow} />
              </div>
            </div>
          </Link>
        </div>
        <div className={styles.right_container}>
          <Link to="/installateur">
            <GatsbyImage image={getImage(imageZimmer)} alt="Installateur" />
            <div className={`${styles.text_button_content} ${styles.right_content}`}>
              <img className={styles.line} src={greenLine} />
              <h2>
                <span>Karl Wall</span> <br /> INSTALLATEUR
              </h2>
              <div className={styles.button}>
                ENTER
                <img className={styles.arrow} src={arrow} />
              </div>
            </div>
          </Link>
        </div>
        <div className={styles.middle_container}>
          <img className={styles.logo} src={logo} alt="logo" />
          <div className={styles.contact}>
            <div className={styles.adress}>
              {contactData.street}, {contactData.postcode} {contactData.city}
            </div>
            <div className={styles.cta}>
              <a className={styles.phone} href={`tel:${contactData.telephone}`}>
                TEL. {contactData.telephone}
              </a>
              <a className={styles.mail} href={`mailto:${contactData.email}`}>
                {contactData.email}
              </a>
            </div>
          </div>
          <div className={styles.impressum_und_datenschutz}>
            <Link className={styles.impressum} to="/impressum">
              Impressum
            </Link>
            <p> & </p>
            <Link className={styles.datenschutz} to="/datenschutz">
              Datenschutzhinweis
            </Link>
          </div>
        </div>
      </section>

      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={title}
        canonicalSiteUrl={canonicalSiteUrl}
        path={location.pathname}
      />
    </LandingPageLayout>
  );
};

export const query = graphql`
  query HomePageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    pageData: cockpitPageHomepage {
      id
      imageInstallateur {
        id
        childImageSharp {
          id
          gatsbyImageData
        }
      }
      imageZimmer {
        id
        childImageSharp {
          id
          gatsbyImageData
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }

      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }

    contactData: cockpitRegionKontaktdaten {
      id
      name
      street
      city
      postcode
      email
      telephone
      fax
    }
  }
`;

export default Homepage;
