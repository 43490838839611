import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import 'sanitize.css';
import './definitions.css';
import './defaults.css';
import './site.css';
import './stickyfooter.css';
import './form.css';
import '../assets/icomoon/style.css';

import { LocaleProvider } from '../helpers/i18n/LocaleContext';
import { CookieNotice } from '../components/CookieNotice';
import { Helmet } from 'react-helmet';

export const LandingPageLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LandingPageLayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <LocaleProvider locale={'de'}>
      <Helmet title={data.site.siteMetadata.title} />
      <div className="pageContainer">
        <main>{children}</main>
      </div>
      <CookieNotice />
    </LocaleProvider>
  );
};
